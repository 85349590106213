@use 'ui/styles/variables' as vars;

.ArticleDetails {
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: fit-content;
  width: 40%;

  h5 {
    color: var(--ui-mutedText);
    font-weight: bold;
    font-size: 12px;
    text-align: left;
  }

  @include vars.mediaQuery("md") {
    >button {
      margin-left: auto;
    }
  }

  .ResultDetail {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    font-size: .875rem;
    gap: 8px;
    min-width: fit-content;

    .DetailLabel {
      color: var(--ui-mutedText);
      display: flex;
      flex: 1;
      text-align: right;
      justify-content: flex-end;
      align-items: flex-start;
      height: 1.25rem;
      line-height: 1.5rem;
    }

    .DetailLink {
      flex: 1 3;
      flex-grow: 1;
      flex-shrink: 1;
      display: flex;
      flex-direction: column;
      gap: 4px;
      justify-content: flex-start;
      line-height: 1.5rem;
      width: inherit;

      a,
      span {
        font-size: 1rem;
        color: var(--core-ink);
        text-decoration: none;
        border-radius: 3px;
        display: flex;
        flex-direction: row;
        align-items: center;
        line-height: calc(1.5rem - 2px);
        text-wrap: balance;

        span {

          // highlight term matches
          mark {
            border-radius: 2px;
            outline: 1px solid var(--ui-pe);
            outline-offset: -1px;
            background-color: var(--ui-highlight);
            padding: 0 3px;
            margin: 0 1px 0px 1px;
          }
        }



        svg {
          fill: var(--ui-mutedText);
          height: 1rem;
          opacity: 0;
        }

        &:focus-visible {
          outline: 2px solid var(--ui-orange);
          outline-offset: 2px;
        }

        &:hover,
        &:focus {
          color: var(--core-ink) !important;

          svg {
            fill: var(--ui-mutedText) !important;
            opacity: 1;
            align-self: center;
          }
        }
      }
    }
  }

  @include vars.mobileOnly() {
    width: 100%;
    align-items: flex-start;
    padding: 0px 24px;
    gap: 8px;

    .ResultDetail:first-of-type {
      .DetailLabel {
        display: none;
      }

      .DetailLink h5 {
        margin-bottom: 0px;
      }
    }

    .ResultDetail {
      flex-direction: column;
      gap: 0px;

      span {
        margin: 0px;
        line-height: 1rem;
      }
    }
  }
}